/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.draws

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.draws.internal.DrawsUseCase
import features.draws.internal.DrawsUseCase.Params
import features.draws.models.DrawsModel


typealias DrawsState = CFlow<DrawsUseCaseResult?>

typealias DrawsUseCaseResult = Result<DrawsModel>

fun DrawsUseCaseResult.serializeDrawsApiResult(): SerializedResult<String> {
    return this.toJsonResult<DrawsModel>()
}

/**
 * Serializes [DrawsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun DrawsState.serializeDrawsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<DrawsModel>()
    }.asCFlow()
}

/**
 * Acquires business draws. [Api] class
 */
class DrawsApi : Api() {

    val drawsState: DrawsState
        get() = _drawsState.asCFlow()

    companion object {
        private val _drawsState: MutableStateFlow<DrawsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val drawsUseCase = DrawsUseCase()

    /**
     * Acquires business draws.
     */
    suspend fun getDraws(
        overrideHeaders: Map<String, String>? = null,

    ): Result<DrawsModel> {
        /* set state to Loading as soon as function is called */
        _drawsState.value = Loading()
        val result = this.drawsUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _drawsState.value = result
            return@drawsUseCase result
        }
        return result
    }

}
