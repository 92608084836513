/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userNotificationPreferencesUpdate

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userNotificationPreferencesUpdate.internal.UpdateNotificationPreferencesUseCase
import features.userNotificationPreferencesUpdate.internal.UpdateNotificationPreferencesUseCase.Params
import features.userNotificationPreferencesUpdate.models.NotificationSettingsModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import features.userNotificationPreferencesUpdate.models.NotificationSettingsUpdateRequest

typealias UpdateNotificationPreferencesState = CFlow<UpdateNotificationPreferencesUseCaseResult?>

typealias UpdateNotificationPreferencesUseCaseResult = Result<NotificationSettingsModel>

fun UpdateNotificationPreferencesUseCaseResult.serializeUpdateNotificationPreferencesApiResult(): SerializedResult<String> {
    return this.toJsonResult<NotificationSettingsModel>()
}

/**
 * Serializes [UpdateNotificationPreferencesState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UpdateNotificationPreferencesState.serializeUpdateNotificationPreferencesState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<NotificationSettingsModel>()
    }.asCFlow()
}

/**
 * Update a users notification settings. This will enable or disable push, sms, and or email notifications from the selected business.  [Api] class
 */
class UpdateNotificationPreferencesApi : Api() {

    val updateNotificationPreferencesState: UpdateNotificationPreferencesState
        get() = _updateNotificationPreferencesState.asCFlow()

    companion object {
        private val _updateNotificationPreferencesState: MutableStateFlow<UpdateNotificationPreferencesUseCaseResult> = MutableStateFlow(Idle())
    }

    private val updateNotificationPreferencesUseCase = UpdateNotificationPreferencesUseCase()

    /**
     * Update a users notification settings. This will enable or disable push, sms, and or email notifications from the selected business. 
     */
    suspend fun updateNotificationPreferences(
        overrideHeaders: Map<String, String>? = null,
        notificationPreferencesRequest: NotificationSettingsUpdateRequest
    ): Result<NotificationSettingsModel> {
        /* set state to Loading as soon as function is called */
        _updateNotificationPreferencesState.value = Loading()
        val result = this.updateNotificationPreferencesUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                notificationPreferencesRequest = notificationPreferencesRequest
            )
        ) { result ->
            _updateNotificationPreferencesState.value = result
            return@updateNotificationPreferencesUseCase result
        }
        return result
    }

    /**
    * Update a users notification settings. This will enable or disable push, sms, and or email notifications from the selected business. 
    *
    * This method is used by the Native artifact when dealing with object body params
    */
    suspend fun updateNotificationPreferences(
        overrideHeaders: Map<String, String>? = null,
        methods: Map<String, Any>
    
    ): Result<NotificationSettingsModel> {
        return updateNotificationPreferences(
            overrideHeaders = overrideHeaders,
            notificationPreferencesRequest = Json.decodeFromString(methods.getValue("notificationPreferencesRequest") as String)
        )
    }
}
