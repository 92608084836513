/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.publicDepositGiftCard

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.publicDepositGiftCard.internal.PublicDepositGiftCardUseCase
import features.publicDepositGiftCard.internal.PublicDepositGiftCardUseCase.Params
import features.publicDepositGiftCard.models.TransferActionResultModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json


typealias PublicDepositGiftCardState = CFlow<PublicDepositGiftCardUseCaseResult?>

typealias PublicDepositGiftCardUseCaseResult = Result<TransferActionResultModel>

fun PublicDepositGiftCardUseCaseResult.serializePublicDepositGiftCardApiResult(): SerializedResult<String> {
    return this.toJsonResult<TransferActionResultModel>()
}

/**
 * Serializes [PublicDepositGiftCardState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun PublicDepositGiftCardState.serializePublicDepositGiftCardState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<TransferActionResultModel>()
    }.asCFlow()
}

/**
 * Deposit a gift card using public API [Api] class
 */
class PublicDepositGiftCardApi : Api() {

    val publicDepositGiftCardState: PublicDepositGiftCardState
        get() = _publicDepositGiftCardState.asCFlow()

    companion object {
        private val _publicDepositGiftCardState: MutableStateFlow<PublicDepositGiftCardUseCaseResult> = MutableStateFlow(Idle())
    }

    private val publicDepositGiftCardUseCase = PublicDepositGiftCardUseCase()

    /**
     * Deposit a gift card using public API
     */
    suspend fun publicDepositGiftCard(
        overrideHeaders: Map<String, String>? = null,
        userId: String,
        messageId: String
    ): Result<TransferActionResultModel> {
        /* set state to Loading as soon as function is called */
        _publicDepositGiftCardState.value = Loading()
        val result = this.publicDepositGiftCardUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                userId = userId,
                messageId = messageId
            )
        ) { result ->
            _publicDepositGiftCardState.value = result
            return@publicDepositGiftCardUseCase result
        }
        return result
    }

}
