/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.getPublicPromotions

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.getPublicPromotions.internal.GetPublicPromotionsUseCase
import features.getPublicPromotions.internal.GetPublicPromotionsUseCase.Params
import features.getPublicPromotions.models.PromotionsModel


typealias GetPublicPromotionsState = CFlow<GetPublicPromotionsUseCaseResult?>

typealias GetPublicPromotionsUseCaseResult = Result<PromotionsModel>

fun GetPublicPromotionsUseCaseResult.serializeGetPublicPromotionsApiResult(): SerializedResult<String> {
    return this.toJsonResult<PromotionsModel>()
}

/**
 * Serializes [GetPublicPromotionsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun GetPublicPromotionsState.serializeGetPublicPromotionsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<PromotionsModel>()
    }.asCFlow()
}

/**
 * Get the promotions.yaml ( offers / rewards ) for a related campaign [Api] class
 */
class GetPublicPromotionsApi : Api() {

    val getPublicPromotionsState: GetPublicPromotionsState
        get() = _getPublicPromotionsState.asCFlow()

    companion object {
        private val _getPublicPromotionsState: MutableStateFlow<GetPublicPromotionsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val getPublicPromotionsUseCase = GetPublicPromotionsUseCase()

    /**
     * Get the promotions.yaml ( offers / rewards ) for a related campaign
     */
    suspend fun getPublicPromotions(
        overrideHeaders: Map<String, String>? = null,
        campaignId: String
    ): Result<PromotionsModel> {
        /* set state to Loading as soon as function is called */
        _getPublicPromotionsState.value = Loading()
        val result = this.getPublicPromotionsUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                campaignId = campaignId
            )
        ) { result ->
            _getPublicPromotionsState.value = result
            return@getPublicPromotionsUseCase result
        }
        return result
    }

}
