/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.archiveInboxItem

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.archiveInboxItem.internal.ArchiveInboxItemUseCase
import features.archiveInboxItem.internal.ArchiveInboxItemUseCase.Params
import features.archiveInboxItem.models.ArchiveResponseModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import features.archiveInboxItem.models.ArchiveRequest

typealias ArchiveInboxItemState = CFlow<ArchiveInboxItemUseCaseResult?>

typealias ArchiveInboxItemUseCaseResult = Result<ArchiveResponseModel>

fun ArchiveInboxItemUseCaseResult.serializeArchiveInboxItemApiResult(): SerializedResult<String> {
    return this.toJsonResult<ArchiveResponseModel>()
}

/**
 * Serializes [ArchiveInboxItemState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun ArchiveInboxItemState.serializeArchiveInboxItemState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<ArchiveResponseModel>()
    }.asCFlow()
}

/**
 * Archive an inbox item.  [Api] class
 */
class ArchiveInboxItemApi : Api() {

    val archiveInboxItemState: ArchiveInboxItemState
        get() = _archiveInboxItemState.asCFlow()

    companion object {
        private val _archiveInboxItemState: MutableStateFlow<ArchiveInboxItemUseCaseResult> = MutableStateFlow(Idle())
    }

    private val archiveInboxItemUseCase = ArchiveInboxItemUseCase()

    /**
     * Archive an inbox item. 
     */
    suspend fun archiveInboxItem(
        overrideHeaders: Map<String, String>? = null,
        archiveRequest: ArchiveRequest
    ): Result<ArchiveResponseModel> {
        /* set state to Loading as soon as function is called */
        _archiveInboxItemState.value = Loading()
        val result = this.archiveInboxItemUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                archiveRequest = archiveRequest
            )
        ) { result ->
            _archiveInboxItemState.value = result
            return@archiveInboxItemUseCase result
        }
        return result
    }

    /**
    * Archive an inbox item. 
    *
    * This method is used by the Native artifact when dealing with object body params
    */
    suspend fun archiveInboxItem(
        overrideHeaders: Map<String, String>? = null,
        methods: Map<String, Any>
    
    ): Result<ArchiveResponseModel> {
        return archiveInboxItem(
            overrideHeaders = overrideHeaders,
            archiveRequest = Json.decodeFromString(methods.getValue("archiveRequest") as String)
        )
    }
}
