/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userUsedCoupons

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userUsedCoupons.internal.UserUsedCouponsUseCase
import features.userUsedCoupons.internal.UserUsedCouponsUseCase.Params
import features.userUsedCoupons.models.UserCouponsModel


typealias UserUsedCouponsState = CFlow<UserUsedCouponsUseCaseResult?>

typealias UserUsedCouponsUseCaseResult = Result<UserCouponsModel>

fun UserUsedCouponsUseCaseResult.serializeUserUsedCouponsApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserCouponsModel>()
}

/**
 * Serializes [UserUsedCouponsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserUsedCouponsState.serializeUserUsedCouponsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserCouponsModel>()
    }.asCFlow()
}

/**
 * Get a list of used coupons for this user. [Api] class
 */
class UserUsedCouponsApi : Api() {

    val userUsedCouponsState: UserUsedCouponsState
        get() = _userUsedCouponsState.asCFlow()

    companion object {
        private val _userUsedCouponsState: MutableStateFlow<UserUsedCouponsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userUsedCouponsUseCase = UserUsedCouponsUseCase()

    /**
     * Get a list of used coupons for this user.
     */
    suspend fun getUserUsedCoupons(
        overrideHeaders: Map<String, String>? = null,

    ): Result<UserCouponsModel> {
        /* set state to Loading as soon as function is called */
        _userUsedCouponsState.value = Loading()
        val result = this.userUsedCouponsUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _userUsedCouponsState.value = result
            return@userUsedCouponsUseCase result
        }
        return result
    }

}
