/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userBusinessFrequentBuyerPrograms

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userBusinessFrequentBuyerPrograms.internal.UserBusinessFrequentBuyerProgramsUseCase
import features.userBusinessFrequentBuyerPrograms.internal.UserBusinessFrequentBuyerProgramsUseCase.Params
import features.userBusinessFrequentBuyerPrograms.models.FrequentBuyerProgramsModel


typealias UserBusinessFrequentBuyerProgramsState = CFlow<UserBusinessFrequentBuyerProgramsUseCaseResult?>

typealias UserBusinessFrequentBuyerProgramsUseCaseResult = Result<FrequentBuyerProgramsModel>

fun UserBusinessFrequentBuyerProgramsUseCaseResult.serializeUserBusinessFrequentBuyerProgramsApiResult(): SerializedResult<String> {
    return this.toJsonResult<FrequentBuyerProgramsModel>()
}

/**
 * Serializes [UserBusinessFrequentBuyerProgramsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserBusinessFrequentBuyerProgramsState.serializeUserBusinessFrequentBuyerProgramsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<FrequentBuyerProgramsModel>()
    }.asCFlow()
}

/**
 * Get a list of frequent buyer programs for this business [Api] class
 */
class UserBusinessFrequentBuyerProgramsApi : Api() {

    val userBusinessFrequentBuyerProgramsState: UserBusinessFrequentBuyerProgramsState
        get() = _userBusinessFrequentBuyerProgramsState.asCFlow()

    companion object {
        private val _userBusinessFrequentBuyerProgramsState: MutableStateFlow<UserBusinessFrequentBuyerProgramsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userBusinessFrequentBuyerProgramsUseCase = UserBusinessFrequentBuyerProgramsUseCase()

    /**
     * Get a list of frequent buyer programs for this business
     */
    suspend fun getUserBusinessFrequentBuyerPrograms(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): Result<FrequentBuyerProgramsModel> {
        /* set state to Loading as soon as function is called */
        _userBusinessFrequentBuyerProgramsState.value = Loading()
        val result = this.userBusinessFrequentBuyerProgramsUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId
            )
        ) { result ->
            _userBusinessFrequentBuyerProgramsState.value = result
            return@userBusinessFrequentBuyerProgramsUseCase result
        }
        return result
    }

}
