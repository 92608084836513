/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.updateDefaultBusinessId

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.updateDefaultBusinessId.internal.UpdateDefaultBusinessIdUseCase
import features.updateDefaultBusinessId.internal.UpdateDefaultBusinessIdUseCase.Params
import features.updateDefaultBusinessId.models.DefaultBusiness


typealias UpdateDefaultBusinessIdState = CFlow<UpdateDefaultBusinessIdUseCaseResult?>

typealias UpdateDefaultBusinessIdUseCaseResult = Result<DefaultBusiness>

fun UpdateDefaultBusinessIdUseCaseResult.serializeUpdateDefaultBusinessIdApiResult(): SerializedResult<String> {
    return this.toJsonResult<DefaultBusiness>()
}

/**
 * Serializes [UpdateDefaultBusinessIdState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UpdateDefaultBusinessIdState.serializeUpdateDefaultBusinessIdState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<DefaultBusiness>()
    }.asCFlow()
}

/**
 * update the last business chosen by the user in the conglomerate feature [Api] class
 */
class UpdateDefaultBusinessIdApi : Api() {

    val updateDefaultBusinessIdState: UpdateDefaultBusinessIdState
        get() = _updateDefaultBusinessIdState.asCFlow()

    companion object {
        private val _updateDefaultBusinessIdState: MutableStateFlow<UpdateDefaultBusinessIdUseCaseResult> = MutableStateFlow(Idle())
    }

    private val updateDefaultBusinessIdUseCase = UpdateDefaultBusinessIdUseCase()

    /**
     * update the last business chosen by the user in the conglomerate feature
     */
    suspend fun updateDefaultBusinessId(
        overrideHeaders: Map<String, String>? = null,
        businessId: String,
        defaultBusinessId: String
    ): Result<DefaultBusiness> {
        /* set state to Loading as soon as function is called */
        _updateDefaultBusinessIdState.value = Loading()
        val result = this.updateDefaultBusinessIdUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId,
                defaultBusinessId = defaultBusinessId
            )
        ) { result ->
            _updateDefaultBusinessIdState.value = result
            return@updateDefaultBusinessIdUseCase result
        }
        return result
    }

}
