/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.publicBusinessSettings

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.publicBusinessSettings.internal.PublicBusinessSettingsUseCase
import features.publicBusinessSettings.internal.PublicBusinessSettingsUseCase.Params
import features.publicBusinessSettings.models.PublicBusinessSettings


typealias PublicBusinessSettingsState = CFlow<PublicBusinessSettingsUseCaseResult?>

typealias PublicBusinessSettingsUseCaseResult = Result<PublicBusinessSettings>

fun PublicBusinessSettingsUseCaseResult.serializePublicBusinessSettingsApiResult(): SerializedResult<String> {
    return this.toJsonResult<PublicBusinessSettings>()
}

/**
 * Serializes [PublicBusinessSettingsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun PublicBusinessSettingsState.serializePublicBusinessSettingsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<PublicBusinessSettings>()
    }.asCFlow()
}

/**
 * Get the public settings of a business. [Api] class
 */
class PublicBusinessSettingsApi : Api() {

    val publicBusinessSettingsState: PublicBusinessSettingsState
        get() = _publicBusinessSettingsState.asCFlow()

    companion object {
        private val _publicBusinessSettingsState: MutableStateFlow<PublicBusinessSettingsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val publicBusinessSettingsUseCase = PublicBusinessSettingsUseCase()

    /**
     * Get the public settings of a business.
     */
    suspend fun getPublicBusinessSettings(
        overrideHeaders: Map<String, String>? = null,

    ): Result<PublicBusinessSettings> {
        /* set state to Loading as soon as function is called */
        _publicBusinessSettingsState.value = Loading()
        val result = this.publicBusinessSettingsUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _publicBusinessSettingsState.value = result
            return@publicBusinessSettingsUseCase result
        }
        return result
    }

}
