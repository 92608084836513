/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userTransferRecall

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userTransferRecall.internal.TransferRecallUseCase
import features.userTransferRecall.internal.TransferRecallUseCase.Params
import features.userTransferRecall.models.TransferActionResultModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json


typealias TransferRecallState = CFlow<TransferRecallUseCaseResult?>

typealias TransferRecallUseCaseResult = Result<TransferActionResultModel>

fun TransferRecallUseCaseResult.serializeTransferRecallApiResult(): SerializedResult<String> {
    return this.toJsonResult<TransferActionResultModel>()
}

/**
 * Serializes [TransferRecallState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun TransferRecallState.serializeTransferRecallState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<TransferActionResultModel>()
    }.asCFlow()
}

/**
 * Recall a transfer. This could be a points transfer, e-wallet transfer, or a gift card  [Api] class
 */
class TransferRecallApi : Api() {

    val transferRecallState: TransferRecallState
        get() = _transferRecallState.asCFlow()

    companion object {
        private val _transferRecallState: MutableStateFlow<TransferRecallUseCaseResult> = MutableStateFlow(Idle())
    }

    private val transferRecallUseCase = TransferRecallUseCase()

    /**
     * Recall a transfer. This could be a points transfer, e-wallet transfer, or a gift card 
     */
    suspend fun recallTransfer(
        overrideHeaders: Map<String, String>? = null,
        recallId: String
    ): Result<TransferActionResultModel> {
        /* set state to Loading as soon as function is called */
        _transferRecallState.value = Loading()
        val result = this.transferRecallUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                recallId = recallId
            )
        ) { result ->
            _transferRecallState.value = result
            return@transferRecallUseCase result
        }
        return result
    }

}
