/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.getDefaultBusinessId

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.getDefaultBusinessId.internal.GetDefaultBusinessIdUseCase
import features.getDefaultBusinessId.internal.GetDefaultBusinessIdUseCase.Params
import features.getDefaultBusinessId.models.DefaultBusiness


typealias GetDefaultBusinessIdState = CFlow<GetDefaultBusinessIdUseCaseResult?>

typealias GetDefaultBusinessIdUseCaseResult = Result<DefaultBusiness>

fun GetDefaultBusinessIdUseCaseResult.serializeGetDefaultBusinessIdApiResult(): SerializedResult<String> {
    return this.toJsonResult<DefaultBusiness>()
}

/**
 * Serializes [GetDefaultBusinessIdState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun GetDefaultBusinessIdState.serializeGetDefaultBusinessIdState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<DefaultBusiness>()
    }.asCFlow()
}

/**
 * Get the last business chosen by the user in teh conglomerate feature [Api] class
 */
class GetDefaultBusinessIdApi : Api() {

    val getDefaultBusinessIdState: GetDefaultBusinessIdState
        get() = _getDefaultBusinessIdState.asCFlow()

    companion object {
        private val _getDefaultBusinessIdState: MutableStateFlow<GetDefaultBusinessIdUseCaseResult> = MutableStateFlow(Idle())
    }

    private val getDefaultBusinessIdUseCase = GetDefaultBusinessIdUseCase()

    /**
     * Get the last business chosen by the user in teh conglomerate feature
     */
    suspend fun getDefaultBusinessId(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): Result<DefaultBusiness> {
        /* set state to Loading as soon as function is called */
        _getDefaultBusinessIdState.value = Loading()
        val result = this.getDefaultBusinessIdUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId
            )
        ) { result ->
            _getDefaultBusinessIdState.value = result
            return@getDefaultBusinessIdUseCase result
        }
        return result
    }

}
