/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.countries

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.countries.internal.CountriesUseCase
import features.countries.internal.CountriesUseCase.Params
import features.countries.models.Countries


typealias CountriesState = CFlow<CountriesUseCaseResult?>

typealias CountriesUseCaseResult = Result<Countries>

fun CountriesUseCaseResult.serializeCountriesApiResult(): SerializedResult<String> {
    return this.toJsonResult<Countries>()
}

/**
 * Serializes [CountriesState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun CountriesState.serializeCountriesState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<Countries>()
    }.asCFlow()
}

/**
 * Get the list of countries supported by Kangaroo [Api] class
 */
class CountriesApi : Api() {

    val countriesState: CountriesState
        get() = _countriesState.asCFlow()

    companion object {
        private val _countriesState: MutableStateFlow<CountriesUseCaseResult> = MutableStateFlow(Idle())
    }

    private val countriesUseCase = CountriesUseCase()

    /**
     * Get the list of countries supported by Kangaroo
     */
    suspend fun getCountries(
        overrideHeaders: Map<String, String>? = null,

    ): Result<Countries> {
        /* set state to Loading as soon as function is called */
        _countriesState.value = Loading()
        val result = this.countriesUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _countriesState.value = result
            return@countriesUseCase result
        }
        return result
    }

}
