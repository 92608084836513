/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userBusinessBanners

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userBusinessBanners.internal.UserBusinessBannersUseCase
import features.userBusinessBanners.internal.UserBusinessBannersUseCase.Params
import features.userBusinessBanners.models.UserBusinessBannersModel


typealias UserBusinessBannersState = CFlow<UserBusinessBannersUseCaseResult?>

typealias UserBusinessBannersUseCaseResult = Result<UserBusinessBannersModel>

fun UserBusinessBannersUseCaseResult.serializeUserBusinessBannersApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserBusinessBannersModel>()
}

/**
 * Serializes [UserBusinessBannersState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserBusinessBannersState.serializeUserBusinessBannersState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserBusinessBannersModel>()
    }.asCFlow()
}

/**
 * Acquires specific business banners. [Api] class
 */
class UserBusinessBannersApi : Api() {

    val userBusinessBannersState: UserBusinessBannersState
        get() = _userBusinessBannersState.asCFlow()

    companion object {
        private val _userBusinessBannersState: MutableStateFlow<UserBusinessBannersUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userBusinessBannersUseCase = UserBusinessBannersUseCase()

    /**
     * Acquires specific business banners.
     */
    suspend fun getUserBusinessBanners(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): Result<UserBusinessBannersModel> {
        /* set state to Loading as soon as function is called */
        _userBusinessBannersState.value = Loading()
        val result = this.userBusinessBannersUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId
            )
        ) { result ->
            _userBusinessBannersState.value = result
            return@userBusinessBannersUseCase result
        }
        return result
    }

}
