/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userRegistration

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userRegistration.internal.UserRegistrationUseCase
import features.userRegistration.internal.UserRegistrationUseCase.Params
import features.userRegistration.models.UserProfileDataModel


typealias UserRegistrationState = CFlow<UserRegistrationUseCaseResult?>

typealias UserRegistrationUseCaseResult = Result<UserProfileDataModel>

fun UserRegistrationUseCaseResult.serializeUserRegistrationApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserProfileDataModel>()
}

/**
 * Serializes [UserRegistrationState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserRegistrationState.serializeUserRegistrationState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserProfileDataModel>()
    }.asCFlow()
}

/**
 * Register a new user. Either a phone number or an email address is required. [Api] class
 */
class UserRegistrationApi : Api() {

    val userRegistrationState: UserRegistrationState
        get() = _userRegistrationState.asCFlow()

    companion object {
        private val _userRegistrationState: MutableStateFlow<UserRegistrationUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userRegistrationUseCase = UserRegistrationUseCase()

    /**
     * Register a new user. Either a phone number or an email address is required.
     */
    suspend fun createAccount(
        overrideHeaders: Map<String, String>? = null,
        email: String? = null,
        phone: String? = null,
        countryCode: String? = null,
        language: String? = null
    ): Result<UserProfileDataModel> {
        /* set state to Loading as soon as function is called */
        _userRegistrationState.value = Loading()
        val result = this.userRegistrationUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                email = email,
                phone = phone,
                countryCode = countryCode,
                language = language
            )
        ) { result ->
            _userRegistrationState.value = result
            return@userRegistrationUseCase result
        }
        return result
    }

}
