/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.publicOffers

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.publicOffers.internal.PublicOffersUseCase
import features.publicOffers.internal.PublicOffersUseCase.Params
import features.publicOffers.models.PublicOffersModel


typealias PublicOffersState = CFlow<PublicOffersUseCaseResult?>

typealias PublicOffersUseCaseResult = Result<PublicOffersModel>

fun PublicOffersUseCaseResult.serializePublicOffersApiResult(): SerializedResult<String> {
    return this.toJsonResult<PublicOffersModel>()
}

/**
 * Serializes [PublicOffersState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun PublicOffersState.serializePublicOffersState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<PublicOffersModel>()
    }.asCFlow()
}

/**
 * Get a list of offers available to all users. [Api] class
 */
class PublicOffersApi : Api() {

    val publicOffersState: PublicOffersState
        get() = _publicOffersState.asCFlow()

    companion object {
        private val _publicOffersState: MutableStateFlow<PublicOffersUseCaseResult> = MutableStateFlow(Idle())
    }

    private val publicOffersUseCase = PublicOffersUseCase()

    /**
     * Get a list of offers available to all users.
     */
    suspend fun getPublicOffers(
        overrideHeaders: Map<String, String>? = null,

    ): Result<PublicOffersModel> {
        /* set state to Loading as soon as function is called */
        _publicOffersState.value = Loading()
        val result = this.publicOffersUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _publicOffersState.value = result
            return@publicOffersUseCase result
        }
        return result
    }

}
