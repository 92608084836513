/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userPinResetWithVerificationCode

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userPinResetWithVerificationCode.internal.UserPinResetUseCase
import features.userPinResetWithVerificationCode.internal.UserPinResetUseCase.Params
import kangaroorewards.appsdk.core.io.EmptyResponse


typealias UserPinResetState = CFlow<UserPinResetUseCaseResult?>

typealias UserPinResetUseCaseResult = Result<EmptyResponse>

fun UserPinResetUseCaseResult.serializeUserPinResetApiResult(): SerializedResult<String> {
    return this.toJsonResult<EmptyResponse>()
}

/**
 * Serializes [UserPinResetState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserPinResetState.serializeUserPinResetState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<EmptyResponse>()
    }.asCFlow()
}

/**
 * Reset a customer PIN. Requires both a verification code and either a phone number or email address  [Api] class
 */
class UserPinResetApi : Api() {

    val userPinResetState: UserPinResetState
        get() = _userPinResetState.asCFlow()

    companion object {
        private val _userPinResetState: MutableStateFlow<UserPinResetUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userPinResetUseCase = UserPinResetUseCase()

    /**
     * Reset a customer PIN. Requires both a verification code and either a phone number or email address 
     */
    suspend fun resetPin(
        overrideHeaders: Map<String, String>? = null,
        verificationCode: String,
        pinCode: String,
        email: String? = null,
        phone: String? = null,
        countryCode: String? = null
    ): Result<EmptyResponse> {
        /* set state to Loading as soon as function is called */
        _userPinResetState.value = Loading()
        val result = this.userPinResetUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                verificationCode = verificationCode,
                pinCode = pinCode,
                email = email,
                phone = phone,
                countryCode = countryCode
            )
        ) { result ->
            _userPinResetState.value = result
            return@userPinResetUseCase result
        }
        return result
    }

}
