/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.redeemReward

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.redeemReward.internal.RedeemRewardsUseCase
import features.redeemReward.internal.RedeemRewardsUseCase.Params
import features.redeemReward.models.RedeemResponseModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import features.redeemReward.models.RedeemRequest

typealias RedeemRewardsState = CFlow<RedeemRewardsUseCaseResult?>

typealias RedeemRewardsUseCaseResult = Result<RedeemResponseModel>

fun RedeemRewardsUseCaseResult.serializeRedeemRewardsApiResult(): SerializedResult<String> {
    return this.toJsonResult<RedeemResponseModel>()
}

/**
 * Serializes [RedeemRewardsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun RedeemRewardsState.serializeRedeemRewardsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<RedeemResponseModel>()
    }.asCFlow()
}

/**
 * Redeem a set of rewards. This action costs points which will be deducted from the users' balance. Only Partner Rewards can be redeemed from the customer api.  [Api] class
 */
class RedeemRewardsApi : Api() {

    val redeemRewardsState: RedeemRewardsState
        get() = _redeemRewardsState.asCFlow()

    companion object {
        private val _redeemRewardsState: MutableStateFlow<RedeemRewardsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val redeemRewardsUseCase = RedeemRewardsUseCase()

    /**
     * Redeem a set of rewards. This action costs points which will be deducted from the users' balance. Only Partner Rewards can be redeemed from the customer api. 
     */
    suspend fun redeemReward(
        overrideHeaders: Map<String, String>? = null,
        redeemRequest: RedeemRequest
    ): Result<RedeemResponseModel> {
        /* set state to Loading as soon as function is called */
        _redeemRewardsState.value = Loading()
        val result = this.redeemRewardsUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                redeemRequest = redeemRequest
            )
        ) { result ->
            _redeemRewardsState.value = result
            return@redeemRewardsUseCase result
        }
        return result
    }

    /**
    * Redeem a set of rewards. This action costs points which will be deducted from the users' balance. Only Partner Rewards can be redeemed from the customer api. 
    *
    * This method is used by the Native artifact when dealing with object body params
    */
    suspend fun redeemReward(
        overrideHeaders: Map<String, String>? = null,
        methods: Map<String, Any>
    
    ): Result<RedeemResponseModel> {
        return redeemReward(
            overrideHeaders = overrideHeaders,
            redeemRequest = Json.decodeFromString(methods.getValue("redeemRequest") as String)
        )
    }
}
