/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.makePayPalPayment

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.makePayPalPayment.internal.PayPalPaymentUseCase
import features.makePayPalPayment.internal.PayPalPaymentUseCase.Params
import features.makePayPalPayment.models.PayPalPaymentModel


typealias PayPalPaymentState = CFlow<PayPalPaymentUseCaseResult?>

typealias PayPalPaymentUseCaseResult = Result<PayPalPaymentModel>

fun PayPalPaymentUseCaseResult.serializePayPalPaymentApiResult(): SerializedResult<String> {
    return this.toJsonResult<PayPalPaymentModel>()
}

/**
 * Serializes [PayPalPaymentState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun PayPalPaymentState.serializePayPalPaymentState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<PayPalPaymentModel>()
    }.asCFlow()
}

/**
 * Create a paypal payment  [Api] class
 */
class PayPalPaymentApi : Api() {

    val payPalPaymentState: PayPalPaymentState
        get() = _payPalPaymentState.asCFlow()

    companion object {
        private val _payPalPaymentState: MutableStateFlow<PayPalPaymentUseCaseResult> = MutableStateFlow(Idle())
    }

    private val payPalPaymentUseCase = PayPalPaymentUseCase()

    /**
     * Create a paypal payment 
     */
    suspend fun makePayPalPayment(
        overrideHeaders: Map<String, String>? = null,
        intent: String = "buy_giftcard",
        provider: String = "paypal",
        giftcardId: Int,
        paypalReturnUrl: String,
        paypalCancelUrl: String
    ): Result<PayPalPaymentModel> {
        /* set state to Loading as soon as function is called */
        _payPalPaymentState.value = Loading()
        val result = this.payPalPaymentUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                intent = intent,
                provider = provider,
                giftcardId = giftcardId,
                paypalReturnUrl = paypalReturnUrl,
                paypalCancelUrl = paypalCancelUrl
            )
        ) { result ->
            _payPalPaymentState.value = result
            return@payPalPaymentUseCase result
        }
        return result
    }

}
