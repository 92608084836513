/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userBusinessUsedCoupons

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userBusinessUsedCoupons.internal.UserBusinessUsedCouponsUseCase
import features.userBusinessUsedCoupons.internal.UserBusinessUsedCouponsUseCase.Params
import features.userBusinessUsedCoupons.models.UserBusinessCouponsModel


typealias UserBusinessUsedCouponsState = CFlow<UserBusinessUsedCouponsUseCaseResult?>

typealias UserBusinessUsedCouponsUseCaseResult = Result<UserBusinessCouponsModel>

fun UserBusinessUsedCouponsUseCaseResult.serializeUserBusinessUsedCouponsApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserBusinessCouponsModel>()
}

/**
 * Serializes [UserBusinessUsedCouponsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserBusinessUsedCouponsState.serializeUserBusinessUsedCouponsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserBusinessCouponsModel>()
    }.asCFlow()
}

/**
 * Get a list of used coupons for this user for a specific business. [Api] class
 */
class UserBusinessUsedCouponsApi : Api() {

    val userBusinessUsedCouponsState: UserBusinessUsedCouponsState
        get() = _userBusinessUsedCouponsState.asCFlow()

    companion object {
        private val _userBusinessUsedCouponsState: MutableStateFlow<UserBusinessUsedCouponsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userBusinessUsedCouponsUseCase = UserBusinessUsedCouponsUseCase()

    /**
     * Get a list of used coupons for this user for a specific business.
     */
    suspend fun getUserBusinessUsedCoupons(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): Result<UserBusinessCouponsModel> {
        /* set state to Loading as soon as function is called */
        _userBusinessUsedCouponsState.value = Loading()
        val result = this.userBusinessUsedCouponsUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId
            )
        ) { result ->
            _userBusinessUsedCouponsState.value = result
            return@userBusinessUsedCouponsUseCase result
        }
        return result
    }

}
