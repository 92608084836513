/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.postOfferFacebookShare

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.postOfferFacebookShare.internal.OfferFacebookShareUseCase
import features.postOfferFacebookShare.internal.OfferFacebookShareUseCase.Params
import features.postOfferFacebookShare.models.UserProfileModel


typealias OfferFacebookShareState = CFlow<OfferFacebookShareUseCaseResult?>

typealias OfferFacebookShareUseCaseResult = Result<UserProfileModel>

fun OfferFacebookShareUseCaseResult.serializeOfferFacebookShareApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserProfileModel>()
}

/**
 * Serializes [OfferFacebookShareState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun OfferFacebookShareState.serializeOfferFacebookShareState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserProfileModel>()
    }.asCFlow()
}

/**
 * Notify Kangaroo that an offer has been shared to facebook [Api] class
 */
class OfferFacebookShareApi : Api() {

    val offerFacebookShareState: OfferFacebookShareState
        get() = _offerFacebookShareState.asCFlow()

    companion object {
        private val _offerFacebookShareState: MutableStateFlow<OfferFacebookShareUseCaseResult> = MutableStateFlow(Idle())
    }

    private val offerFacebookShareUseCase = OfferFacebookShareUseCase()

    /**
     * Notify Kangaroo that an offer has been shared to facebook
     */
    suspend fun postOfferFacebookShare(
        overrideHeaders: Map<String, String>? = null,
        offerId: String,
        include: String = "balance",
        facebookUserId: String,
        type: String = "facebook_share",
        friendsCount: String = "1"
    ): Result<UserProfileModel> {
        /* set state to Loading as soon as function is called */
        _offerFacebookShareState.value = Loading()
        val result = this.offerFacebookShareUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                offerId = offerId,
                include = include,
                facebookUserId = facebookUserId,
                type = type,
                friendsCount = friendsCount
            )
        ) { result ->
            _offerFacebookShareState.value = result
            return@offerFacebookShareUseCase result
        }
        return result
    }

}
