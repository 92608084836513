/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userAlaCarte

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userAlaCarte.internal.AlaCarteProductsUseCase
import features.userAlaCarte.internal.AlaCarteProductsUseCase.Params
import features.userAlaCarte.models.AlaCarteProductsModel


typealias AlaCarteProductsState = CFlow<AlaCarteProductsUseCaseResult?>

typealias AlaCarteProductsUseCaseResult = Result<AlaCarteProductsModel>

fun AlaCarteProductsUseCaseResult.serializeAlaCarteProductsApiResult(): SerializedResult<String> {
    return this.toJsonResult<AlaCarteProductsModel>()
}

/**
 * Serializes [AlaCarteProductsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun AlaCarteProductsState.serializeAlaCarteProductsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<AlaCarteProductsModel>()
    }.asCFlow()
}

/**
 * Get user A la carte list [Api] class
 */
class AlaCarteProductsApi : Api() {

    val alaCarteProductsState: AlaCarteProductsState
        get() = _alaCarteProductsState.asCFlow()

    companion object {
        private val _alaCarteProductsState: MutableStateFlow<AlaCarteProductsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val alaCarteProductsUseCase = AlaCarteProductsUseCase()

    /**
     * Get user A la carte list
     */
    suspend fun getAlaCarteProducts(
        overrideHeaders: Map<String, String>? = null,

    ): Result<AlaCarteProductsModel> {
        /* set state to Loading as soon as function is called */
        _alaCarteProductsState.value = Loading()
        val result = this.alaCarteProductsUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _alaCarteProductsState.value = result
            return@alaCarteProductsUseCase result
        }
        return result
    }

}
