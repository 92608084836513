/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userBusinessProducts

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userBusinessProducts.internal.UserBusinessProductsUseCase
import features.userBusinessProducts.internal.UserBusinessProductsUseCase.Params
import features.userBusinessProducts.models.UserProductsModel


typealias UserBusinessProductsState = CFlow<UserBusinessProductsUseCaseResult?>

typealias UserBusinessProductsUseCaseResult = Result<UserProductsModel>

fun UserBusinessProductsUseCaseResult.serializeUserBusinessProductsApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserProductsModel>()
}

/**
 * Serializes [UserBusinessProductsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserBusinessProductsState.serializeUserBusinessProductsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserProductsModel>()
    }.asCFlow()
}

/**
 * Get user products list by specific business [Api] class
 */
class UserBusinessProductsApi : Api() {

    val userBusinessProductsState: UserBusinessProductsState
        get() = _userBusinessProductsState.asCFlow()

    companion object {
        private val _userBusinessProductsState: MutableStateFlow<UserBusinessProductsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userBusinessProductsUseCase = UserBusinessProductsUseCase()

    /**
     * Get user products list by specific business
     */
    suspend fun getUserBusinessProducts(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): Result<UserProductsModel> {
        /* set state to Loading as soon as function is called */
        _userBusinessProductsState.value = Loading()
        val result = this.userBusinessProductsUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId
            )
        ) { result ->
            _userBusinessProductsState.value = result
            return@userBusinessProductsUseCase result
        }
        return result
    }

}
