/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userPinResetWithToken

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userPinResetWithToken.internal.UserPinResetWithTokenUseCase
import features.userPinResetWithToken.internal.UserPinResetWithTokenUseCase.Params
import kangaroorewards.appsdk.core.io.EmptyResponse
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import features.userPinResetWithToken.models.PinResetWithTokenRequestModel

typealias UserPinResetWithTokenState = CFlow<UserPinResetWithTokenUseCaseResult?>

typealias UserPinResetWithTokenUseCaseResult = Result<EmptyResponse>

fun UserPinResetWithTokenUseCaseResult.serializeUserPinResetWithTokenApiResult(): SerializedResult<String> {
    return this.toJsonResult<EmptyResponse>()
}

/**
 * Serializes [UserPinResetWithTokenState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserPinResetWithTokenState.serializeUserPinResetWithTokenState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<EmptyResponse>()
    }.asCFlow()
}

/**
 * Reset a customer PIN. Requires both a token and either a phone number or email address  [Api] class
 */
class UserPinResetWithTokenApi : Api() {

    val userPinResetWithTokenState: UserPinResetWithTokenState
        get() = _userPinResetWithTokenState.asCFlow()

    companion object {
        private val _userPinResetWithTokenState: MutableStateFlow<UserPinResetWithTokenUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userPinResetWithTokenUseCase = UserPinResetWithTokenUseCase()

    /**
     * Reset a customer PIN. Requires both a token and either a phone number or email address 
     */
    suspend fun resetPinWithToken(
        overrideHeaders: Map<String, String>? = null,
        pinResetWithTokenRequest: PinResetWithTokenRequestModel
    ): Result<EmptyResponse> {
        /* set state to Loading as soon as function is called */
        _userPinResetWithTokenState.value = Loading()
        val result = this.userPinResetWithTokenUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                pinResetWithTokenRequest = pinResetWithTokenRequest
            )
        ) { result ->
            _userPinResetWithTokenState.value = result
            return@userPinResetWithTokenUseCase result
        }
        return result
    }

    /**
    * Reset a customer PIN. Requires both a token and either a phone number or email address 
    *
    * This method is used by the Native artifact when dealing with object body params
    */
    suspend fun resetPinWithToken(
        overrideHeaders: Map<String, String>? = null,
        methods: Map<String, Any>
    
    ): Result<EmptyResponse> {
        return resetPinWithToken(
            overrideHeaders = overrideHeaders,
            pinResetWithTokenRequest = Json.decodeFromString(methods.getValue("pinResetWithTokenRequest") as String)
        )
    }
}
