/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.getCrmFields

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.getCrmFields.internal.GetCrmFieldsUseCase
import features.getCrmFields.internal.GetCrmFieldsUseCase.Params
import features.getCrmFields.models.CrmFields


typealias GetCrmFieldsState = CFlow<GetCrmFieldsUseCaseResult?>

typealias GetCrmFieldsUseCaseResult = Result<CrmFields>

fun GetCrmFieldsUseCaseResult.serializeGetCrmFieldsApiResult(): SerializedResult<String> {
    return this.toJsonResult<CrmFields>()
}

/**
 * Serializes [GetCrmFieldsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun GetCrmFieldsState.serializeGetCrmFieldsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<CrmFields>()
    }.asCFlow()
}

/**
 * Get a list of the business crm fields [Api] class
 */
class GetCrmFieldsApi : Api() {

    val getCrmFieldsState: GetCrmFieldsState
        get() = _getCrmFieldsState.asCFlow()

    companion object {
        private val _getCrmFieldsState: MutableStateFlow<GetCrmFieldsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val getCrmFieldsUseCase = GetCrmFieldsUseCase()

    /**
     * Get a list of the business crm fields
     */
    suspend fun getCrmFields(
        overrideHeaders: Map<String, String>? = null,

    ): Result<CrmFields> {
        /* set state to Loading as soon as function is called */
        _getCrmFieldsState.value = Loading()
        val result = this.getCrmFieldsUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _getCrmFieldsState.value = result
            return@getCrmFieldsUseCase result
        }
        return result
    }

}
