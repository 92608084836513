/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userBusinessOffers

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userBusinessOffers.internal.UserBusinessOffersUseCase
import features.userBusinessOffers.internal.UserBusinessOffersUseCase.Params
import features.userBusinessOffers.models.UserBusinessOffersModel


typealias UserBusinessOffersState = CFlow<UserBusinessOffersUseCaseResult?>

typealias UserBusinessOffersUseCaseResult = Result<UserBusinessOffersModel>

fun UserBusinessOffersUseCaseResult.serializeUserBusinessOffersApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserBusinessOffersModel>()
}

/**
 * Serializes [UserBusinessOffersState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserBusinessOffersState.serializeUserBusinessOffersState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserBusinessOffersModel>()
    }.asCFlow()
}

/**
 * Get a list of offers available to this user by specific business. [Api] class
 */
class UserBusinessOffersApi : Api() {

    val userBusinessOffersState: UserBusinessOffersState
        get() = _userBusinessOffersState.asCFlow()

    companion object {
        private val _userBusinessOffersState: MutableStateFlow<UserBusinessOffersUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userBusinessOffersUseCase = UserBusinessOffersUseCase()

    /**
     * Get a list of offers available to this user by specific business.
     */
    suspend fun getUserBusinessOffers(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): Result<UserBusinessOffersModel> {
        /* set state to Loading as soon as function is called */
        _userBusinessOffersState.value = Loading()
        val result = this.userBusinessOffersUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId
            )
        ) { result ->
            _userBusinessOffersState.value = result
            return@userBusinessOffersUseCase result
        }
        return result
    }

}
