/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.strings

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.strings.internal.StringsUseCase
import features.strings.internal.StringsUseCase.Params
import features.strings.models.ApplicationStringsModel


typealias StringsState = CFlow<StringsUseCaseResult?>

typealias StringsUseCaseResult = Result<ApplicationStringsModel>

fun StringsUseCaseResult.serializeStringsApiResult(): SerializedResult<String> {
    return this.toJsonResult<ApplicationStringsModel>()
}

/**
 * Serializes [StringsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun StringsState.serializeStringsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<ApplicationStringsModel>()
    }.asCFlow()
}

/**
 * Acquires application strings. [Api] class
 */
class StringsApi : Api() {

    val stringsState: StringsState
        get() = _stringsState.asCFlow()

    companion object {
        private val _stringsState: MutableStateFlow<StringsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val stringsUseCase = StringsUseCase()

    /**
     * Acquires application strings.
     */
    suspend fun getStrings(
        overrideHeaders: Map<String, String>? = null,

    ): Result<ApplicationStringsModel> {
        /* set state to Loading as soon as function is called */
        _stringsState.value = Loading()
        val result = this.stringsUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _stringsState.value = result
            return@stringsUseCase result
        }
        return result
    }

}
