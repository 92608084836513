/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userProfileUpdate

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userProfileUpdate.internal.UserProfileUpdateUseCase
import features.userProfileUpdate.internal.UserProfileUpdateUseCase.Params
import features.userProfileUpdate.models.UserProfileModel


typealias UserProfileUpdateState = CFlow<UserProfileUpdateUseCaseResult?>

typealias UserProfileUpdateUseCaseResult = Result<UserProfileModel>

fun UserProfileUpdateUseCaseResult.serializeUserProfileUpdateApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserProfileModel>()
}

/**
 * Serializes [UserProfileUpdateState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserProfileUpdateState.serializeUserProfileUpdateState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserProfileModel>()
    }.asCFlow()
}

/**
 * Update a users' profile [Api] class
 */
class UserProfileUpdateApi : Api() {

    val userProfileUpdateState: UserProfileUpdateState
        get() = _userProfileUpdateState.asCFlow()

    companion object {
        private val _userProfileUpdateState: MutableStateFlow<UserProfileUpdateUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userProfileUpdateUseCase = UserProfileUpdateUseCase()

    /**
     * Update a users' profile
     */
    suspend fun updateUserProfile(
        overrideHeaders: Map<String, String>? = null,
        firstName: String? = null,
        lastName: String? = null,
        birthDate: String? = null,
        language: String? = null,
        gender: String? = null,
        profilePhoto: String? = null
    ): Result<UserProfileModel> {
        /* set state to Loading as soon as function is called */
        _userProfileUpdateState.value = Loading()
        val result = this.userProfileUpdateUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                firstName = firstName,
                lastName = lastName,
                birthDate = birthDate,
                language = language,
                gender = gender,
                profilePhoto = profilePhoto
            )
        ) { result ->
            _userProfileUpdateState.value = result
            return@userProfileUpdateUseCase result
        }
        return result
    }

}
