/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userBusinessProfile

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userBusinessProfile.internal.UserBusinessProfileUseCase
import features.userBusinessProfile.internal.UserBusinessProfileUseCase.Params
import features.userBusinessProfile.models.UserBusinessProfileModel


typealias UserBusinessProfileState = CFlow<UserBusinessProfileUseCaseResult?>

typealias UserBusinessProfileUseCaseResult = Result<UserBusinessProfileModel>

fun UserBusinessProfileUseCaseResult.serializeUserBusinessProfileApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserBusinessProfileModel>()
}

/**
 * Serializes [UserBusinessProfileState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserBusinessProfileState.serializeUserBusinessProfileState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserBusinessProfileModel>()
    }.asCFlow()
}

/**
 * Get user profile data for this user for specific business. [Api] class
 */
class UserBusinessProfileApi : Api() {

    val userBusinessProfileState: UserBusinessProfileState
        get() = _userBusinessProfileState.asCFlow()

    companion object {
        private val _userBusinessProfileState: MutableStateFlow<UserBusinessProfileUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userBusinessProfileUseCase = UserBusinessProfileUseCase()

    /**
     * Get user profile data for this user for specific business.
     */
    suspend fun getUserBusinessProfile(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): Result<UserBusinessProfileModel> {
        /* set state to Loading as soon as function is called */
        _userBusinessProfileState.value = Loading()
        val result = this.userBusinessProfileUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId
            )
        ) { result ->
            _userBusinessProfileState.value = result
            return@userBusinessProfileUseCase result
        }
        return result
    }

}
