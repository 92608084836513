/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.publicBranches

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.publicBranches.internal.PublicBranchesUseCase
import features.publicBranches.internal.PublicBranchesUseCase.Params
import features.publicBranches.models.PublicBranchesModel


typealias PublicBranchesState = CFlow<PublicBranchesUseCaseResult?>

typealias PublicBranchesUseCaseResult = Result<PublicBranchesModel>

fun PublicBranchesUseCaseResult.serializePublicBranchesApiResult(): SerializedResult<String> {
    return this.toJsonResult<PublicBranchesModel>()
}

/**
 * Serializes [PublicBranchesState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun PublicBranchesState.serializePublicBranchesState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<PublicBranchesModel>()
    }.asCFlow()
}

/**
 * Get a list of branches available to all users. [Api] class
 */
class PublicBranchesApi : Api() {

    val publicBranchesState: PublicBranchesState
        get() = _publicBranchesState.asCFlow()

    companion object {
        private val _publicBranchesState: MutableStateFlow<PublicBranchesUseCaseResult> = MutableStateFlow(Idle())
    }

    private val publicBranchesUseCase = PublicBranchesUseCase()

    /**
     * Get a list of branches available to all users.
     */
    suspend fun getPublicBranches(
        overrideHeaders: Map<String, String>? = null,
        perPage: Int
    ): Result<PublicBranchesModel> {
        /* set state to Loading as soon as function is called */
        _publicBranchesState.value = Loading()
        val result = this.publicBranchesUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                perPage = perPage
            )
        ) { result ->
            _publicBranchesState.value = result
            return@publicBranchesUseCase result
        }
        return result
    }

}
