/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userBusinessRewards

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import features.userBusinessRewards.internal.UserBusinessRewardsUseCase
import features.userBusinessRewards.internal.UserBusinessRewardsUseCase.Params
import features.userBusinessRewards.models.UserBusinessRewardsModel


typealias UserBusinessRewardsState = CFlow<UserBusinessRewardsUseCaseResult?>

typealias UserBusinessRewardsUseCaseResult = Result<UserBusinessRewardsModel>

fun UserBusinessRewardsUseCaseResult.serializeUserBusinessRewardsApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserBusinessRewardsModel>()
}

/**
 * Serializes [UserBusinessRewardsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserBusinessRewardsState.serializeUserBusinessRewardsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserBusinessRewardsModel>()
    }.asCFlow()
}

/**
 * Get a list of rewards available to this user by specific business. [Api] class
 */
class UserBusinessRewardsApi : Api() {

    val userBusinessRewardsState: UserBusinessRewardsState
        get() = _userBusinessRewardsState.asCFlow()

    companion object {
        private val _userBusinessRewardsState: MutableStateFlow<UserBusinessRewardsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userBusinessRewardsUseCase = UserBusinessRewardsUseCase()

    /**
     * Get a list of rewards available to this user by specific business.
     */
    suspend fun getUserBusinessRewards(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): Result<UserBusinessRewardsModel> {
        /* set state to Loading as soon as function is called */
        _userBusinessRewardsState.value = Loading()
        val result = this.userBusinessRewardsUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId
            )
        ) { result ->
            _userBusinessRewardsState.value = result
            return@userBusinessRewardsUseCase result
        }
        return result
    }

}
